import { useToast } from "@/components/ui/use-toast";
import { api } from "@/lib/axios"
import { Question, Quiz, SubmittedData } from "@/types/quiz"
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query"

export const useGetQuizzes = (params: {
  search: string;
  page: number;
  fullList?: boolean
  slug: string | undefined;
}) => {
  return useQuery({
    enabled: Boolean(params.slug),
    queryKey: ['course-quizzes', params],
    queryFn: () => api.get('/quiz', { params })
  })
}

export const useGetSubmittedSessions = (params: {
  search: string;
  page: number;
  fullList?: boolean
  slug: string | undefined;
}) => {
  return useQuery({
    enabled: Boolean(params.slug),
    queryKey: ['submitted-sessions', params],
    queryFn: () => api.get('/quiz/sessions', { params })
  })
}

export const useUpsertSubmittedSessions = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    quizSuccessfullyupdatedT
  ] = [
    'quizSuccessfullyupdated',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: {
      attempt_token: string
      submitted_data: SubmittedData[]
      review: boolean
    }) => api.put('/quiz/sessions', payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['submitted-sessions'])
      toast({
        title: `${quizSuccessfullyupdatedT}`
      })
    }
  })
}

export const useGetQuiz = (id: string | null) => {
  return useQuery({
    enabled: Boolean(id),
    queryKey: ['quizz', id],
    queryFn: () => api.get(`/quiz/${id}`)
  })
}

export const useUpsertQuiz = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    quizSuccessfullyupdatedT
  ] = [
    'quizSuccessfullyupdated',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (payload: {_id: string} & Quiz) => api.put('/quiz', payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['course-quizzes'])
      toast({
        title: `${quizSuccessfullyupdatedT}`
      })
    }
  })
}

export const useDeleteQuiz = () => {
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()
  const [
    quizSuccessfullyDeletedT
  ] = [
    'quizSuccessfullyDeleted',
  ].map(key => get(t('toast'), key, ''))

  return useMutation({
    mutationFn: (id: string) => api.delete(`/quiz/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['course-quizzes'])
      toast({
        title: `${quizSuccessfullyDeletedT}`
      })
    }
  })
}

export const useReviewStatusQuiz = (id:string | undefined) => {
  return useQuery({
    queryKey: ['review-quiz-submission'],
    queryFn: () => id ? api.get(`/quiz/${id}/review`) : null
  })
}

export const useStartQuiz = () => {
  return useMutation({
    mutationFn: (id: string) => api.post(`/quiz/${id}/start`)
  })
}

export const useSubmitQuiz = () => {
  const { toast } = useToast()

  type Payload = {
    id: string,
    token: string
    data: Question[]
  }

  return useMutation({
    mutationFn: (payload: Payload) => api.post(`/quiz/${payload.id}/submit`, {
      token: payload.token,
      data: payload.data
    }),
    onError: (error: unknown) => {
      toast({
        title: get(error, 'response.data.message', 'hello world')
      })
    }
  })
}